import NotFound from "#screens/NotFound";
import { RouteType } from "#interfaces/routes";
import Landing from "#screens/Landing";

const ROUTES: RouteType[] = [
  {
    path: "/coffeetracker/locationId/:locationId",
    component: Landing,
  },
  {
    path: "*",
    component: NotFound,
  },
];

export default ROUTES;
