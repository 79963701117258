import HTTPClient from "#api/HTTPClient";
import {
  Error,
  FacilityTransactionData,
  Response,
} from "#containers/OurCoffee/types";

export const getLastFacilityTransactions = async (
  locationId: string,
): Promise<FacilityTransactionData | Error> => {
  const client = HTTPClient.getClient();
  const response = await client.post(
    `transactions/getLastFacilityTransaction`,
    { vendorFacility: locationId },
    {},
    false,
    [],
  );
  /**
   *  Success
   */
  const dataResponse: Response = response;

  if (response.statusCode.toUpperCase() === "OK") {
    return dataResponse.body;
  }

  /**
   * Error
   */
  return Promise.reject({
    body: dataResponse.body,
    statusCodeValue: dataResponse.statusCodeValue,
  });
};
