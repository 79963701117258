// Icons
import { ReactComponent as Facebook } from "#assets/svgs/facebook.svg";
import { ReactComponent as Instagram } from "#assets/svgs/instagram.svg";
import { ReactComponent as TwitterX } from "#assets/svgs/x.svg";
import { FormattedMessage } from "react-intl";

const Footer = () => {
  return (
    <div
      className="
        component-footer
        w-full 
        bg-dark-secondary 
        min-h-[175px] 
        md:min-h-[unset]
        pt-12 
        pb-8
        flex
        flex-col
        items-center
        justify-center
        md:pt-10
        md:px-9
        lg:px-16
        "
    >
      <div
        className="
            flex
            flex-col
            items-center
            justify-center
            w-full
            gap-6
            md:flex-row
            md:justify-between
            md:max-w-[800px] 
            lg:max-w-[1000px] 
            xl:max-w-[1300px]
            "
      >
        <div className="flex flex-column gap-8 md:gap-4">
          <a
            href="https://www.facebook.com/ToksMx"
            className="transition duration-200 ease-in-out hover:ease-in hover:scale-125"
            target="_blank"
            rel="noreferrer"
          >
            <Facebook className="w-[56px] h-[56px] md:w-[40px] md:h-[40px]" />
          </a>
          <a
            href="https://www.instagram.com/ToksMx/"
            className="transition duration-200 ease-in-out hover:ease-in hover:scale-125"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram className="w-[56px] h-[56px] md:w-[40px] md:h-[40px]" />
          </a>
          <a
            href="https://twitter.com/ToksMx"
            className="transition duration-200 ease-in-out hover:ease-in hover:scale-125"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterX className="w-[56px] h-[56px] md:w-[40px] md:h-[40px]" />
          </a>
        </div>
        <a
          href="https://www.toks.com.mx/?lang=es"
          target="_blank"
          rel="noreferrer"
          className="website-text text-cream-primary"
        >
          <FormattedMessage id="general.footer.main.website" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
