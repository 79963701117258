import { FormattedMessage } from "react-intl";
import { ReactComponent as PlayIcon } from "#assets/svgs/play-icon.svg";
import { PlayButtonProps } from "./types";

/**
 * PlayButton component
 * @returns a button to play a video
 */
const PlayButton = ({ onClick }: PlayButtonProps) => {
  return (
    <button
      className="
        w-[138px] h-[40px] 
        rounded-full 
        bg-orange-primary 
        cursor-[unset] md:hover:cursor-pointer 
        text-white-primary
        flex items-center justify-center
      "
      onClick={() => {
        onClick();
      }}
    >
      <PlayIcon />
      <span className="producer-text ml-2">
        <FormattedMessage id="our.producers.button.video" />
      </span>
    </button>
  );
};

export default PlayButton;
