import Header from "#components/layouts/Header";
import HeroContent from "#components/ui/HeroContent";

/**
 * Hero section component
 * @renders Header, hero background and copies
 */
const HeroSection = () => {
  return (
    /* Background */
    <div
      className={`
        component-hero-section
        bg-[url('/src/assets/svgs/hero-image-mobile.svg')] 
        bg-no-repeat 
        bg-cover
        bg-center 
        h-auto 
        relative 
        pt-11 pr-4 pl-4
        pb-[20px]
        md:pb-[5rem]
        md:h-auto
        md:bg-[url('/src/assets/svgs/hero-image-desktop.svg')]
        text-center
        z-[1]
        md:pt-16
      `}
      id="hero-section"
    >
      {/* linear gradient */}
      <div className="h-full w-full linear-gradient min-[380px]:linear-gradient-sm md:linear-gradient-md absolute top-0 left-0 z-[-1]" />

      {/* Logo */}
      <Header />

      {/* Hero Content */}
      <HeroContent />
    </div>
  );
};

export default HeroSection;
