import { FormattedMessage } from "react-intl";
import InfoComponent from "#components/ui/InfoComponent";
import { useHandleWindowResize } from "#hooks/useHandleWindowResize";

// images
import chiapasState from "#assets/pngs/chiapas-state.png";
import scoopOfCoffee from "#assets/pngs/coffee-scoop.png";

/**
 * Origin and certifications section
 * @renders background, header, images, subtitle and paragraphs
 */
const OriginAndCertifications = () => {
  const { isMobile } = useHandleWindowResize();
  return (
    <div
      className="component-origin-and-certifications-section"
      id="origin-and-certifications"
    >
      <div className="linear-gradient-180deg flex justify-center items-center pb-3 px-6 lg:justify-center">
        <div className="transform-180 max-w-[1300px] flex justify-center items-center md:items-start w-full md:justify-evenly flex-col md:flex-row-reverse">
          {/* Producers from Tacaná */}
          <div className="max-w-[330px] md:max-w-[50%] mt-8 md:mt-8 lg:mt-16">
            {/* Mobile */}
            <h4 className="title-4 text-orange-primary md:hidden">
              <FormattedMessage id="origin.section.header" />
            </h4>
            {/* Desktop */}
            <h2 className="hidden md:block title-2 text-orange-primary">
              <FormattedMessage id="origin.section.header" />
            </h2>
            {/* Info component (subtitle and paragraph) */}
            <InfoComponent
              subtitle="origin.section.producers.subtitle"
              paragraph={`origin.section.producers.paragraph${
                isMobile ? ".mobile" : ""
              }`}
            />
          </div>

          {/* Map */}
          <div className="">
            <img
              src={chiapasState}
              alt="Chiapas state"
              className="mt-0 w-auto max-w-[320px] lg:max-w-[400px] xl:max-w-[466px]"
            />
          </div>
        </div>
      </div>
      <div className="linear-gradient-0deg flex justify-center items-center py-3 px-6 lg:justify-center relative">
        {/* Coffee scoop image */}
        <img
          className="
            w-full     
            bg-no-repeat 
            bg-cover
            bg-center
            absolute
            top-[1rem]
            lg:top-[-3rem]
            right-0
            bottom-[370px]
            hidden
            md:block
            max-w-[400px]
            lg:max-w-[500px]
            xl:max-w-[588px]
          "
          src={scoopOfCoffee}
          alt="coffee scoop"
        />
        <div className="max-w-[1300px] flex w-full flex-col">
          {/* Divider */}
          <div
            className="
            bg-orange-primary h-[1px]
              w-full 
              max-w-[330px]
              md:max-w-[60%] 
              my-2
              self-center
              md:self-start 
              md:my-8
              lg:max-w-[58%]
              xxl:max-w-[600px]
              xxl:ml-[10% - 60px]
            "
          />
          {/* Organic certifications */}
          <div
            className="
              flex
              flex-col 
              items-center 
              w-full 
              md:flex-row 
              md:justify-between 
              md:max-w-[800px] 
              lg:max-w-[1000px] 
              xl:max-w-[1300px]
              pb-10
            "
          >
            {/* Info component (subtitle and paragraph) */}
            <InfoComponent
              subtitle="origin.section.certifications.subtitle"
              paragraph="origin.section.certifications.paragraph"
              className="max-w-[305px]"
              certifications
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OriginAndCertifications;
