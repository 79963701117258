import { FormattedMessage } from "react-intl";
import coffeeLeaves from "#assets/pngs/coffee-leaves.png";
import coffeeBeansSack from "#assets/pngs/coffee-beans-sack.png";
import CharacteristicsCarousel from "./CharacteristicsCarousel";
import BeanCharacteristic from "./BeanCharacteristic";
import { BeanCharacteristicsList } from "./constants";

/**
 * BeanCharacteristics component
 * @returns Coffee bean characteristics slide
 */
const BeanCharacteristics = () => {
  return (
    <div className="bean-characteristics-section linear-gradient-0deg flex justify-center items-center py-6 px-[18px] lg:justify-center relative">
      <div className="max-w-[1000px] min-h-[525px] flex items-center w-full flex-col pt-20 md:pt-0">
        {/* Left image visible since Mobile */}
        <img
          src={coffeeLeaves}
          alt="coffee leaves"
          className="w-[125px] absolute top-[-50px] md:top-[80px] left-0 lg:w-[170px] z-[1]"
        />
        {/* Mobile */}
        <h4 className="title-4 text-orange-primary md:hidden">
          <FormattedMessage id="our.coffee.characteristics.title" />
        </h4>
        {/* Desktop */}
        <h2 className="hidden md:block title-2 text-orange-primary">
          <FormattedMessage id="our.coffee.characteristics.title" />
        </h2>
        {/* Coffee bean characteristics slide */}
        <div className="w-full md:mt-10">
          {/* Desktop version */}
          <div className="hidden md:flex flex-wrap gap-5 md:gap-[2rem] xl:gap-[4rem] justify-center">
            {BeanCharacteristicsList.map(bc => (
              <BeanCharacteristic
                characteristic={bc.characteristic}
                description={bc.description}
                Icon={bc.Icon}
                key={`bean-characteristic-${Math.random()}`}
              />
            ))}
          </div>
          {/* Mobile version */}
          <div className="flex md:hidden mt-10 justify-center">
            <CharacteristicsCarousel />
          </div>
        </div>
        {/* Right image visible since Desktop */}
        <img
          src={coffeeBeansSack}
          alt="coffee beans sack"
          className="
            hidden md:block 
            w-[220px] lg:w-[285px] xl:w-[335px] 
            absolute right-0 bottom-[-60px] lg:bottom-[-75px] xl:bottom-[-90px] 
            z-[10]
          "
        />
      </div>
    </div>
  );
};

export default BeanCharacteristics;
