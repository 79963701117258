import { useMemo } from "react";
import ReactClearModal from "react-clear-modal";
import YouTube from "react-youtube";
// assets
import { ReactComponent as CloseIcon } from "#assets/svgs/close-icon.svg";
// hooks
import { useHandleWindowResize } from "#hooks/useHandleWindowResize";
// types
import { YoutubeModalProps } from "./types";

/**
 * YoutubeModal component
 * @returns a modal to display a youtube video
 */
const YoutubeModal = (props: YoutubeModalProps) => {
  /**
   * Hooks
   */
  const { isMobile } = useHandleWindowResize();
  /**
   * Memos
   */
  const opts = useMemo(
    () => ({
      height: isMobile ? "240" : "480",
      width: isMobile ? "320" : "640",
      playerVars: {
        autoplay: 1,
      },
    }),
    [isMobile],
  );
  /**
   * return
   */
  return (
    <ReactClearModal
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        padding: 8,
        zIndex: 999,
      }}
      isOpen={props.open}
      onRequestClose={isMobile ? props.onClose : undefined}
      contentProps={{
        style: {
          background: "transparent",
          maxWidth: 700,
          padding: 16,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <div>
        <YouTube videoId={props.youtubeId} opts={opts} />
      </div>

      <button
        className="
          bg-dark-primary w-[40px] h-[40px]
          flex justify-center items-center
          m-2
        "
        type="button"
        title="Close"
        onClick={props.onClose}
      >
        <CloseIcon className="w-[20px] h-[20px]" />
      </button>
    </ReactClearModal>
  );
};

export default YoutubeModal;
