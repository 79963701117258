import { createIntl, RawIntlProvider, createIntlCache } from "react-intl";

type Props = {
  children: JSX.Element;
};

const CustomIntlProvider = ({ children }: Props) => {
  const cache = createIntlCache();
  const messagesES = require("../i18n/reactIntlMessages-es-MX.json");

  const intl = createIntl(
    {
      locale: "es-mx",
      messages: messagesES,
    },
    cache,
  );

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};
export default CustomIntlProvider;
