import { FormattedMessage } from "react-intl";
import { BeanCharacteristicProps } from "./types";

/**
 * BeanCharacteristic component
 * @returns a coffee bean characteristic
 */
const BeanCharacteristic = ({
  characteristic,
  description,
  Icon,
}: BeanCharacteristicProps) => {
  return (
    <div className="w-[280px] p-2 flex items-center flex-col text-cream-primary">
      <Icon className="w-[78px] mb-5" />
      <span className="characteristic-title mb-[8px]">
        <FormattedMessage id={characteristic} />
      </span>
      <span className="characteristic-description">
        <FormattedMessage id={description} />
      </span>
    </div>
  );
};

export default BeanCharacteristic;
