/**
 * Custom styles
 */
export const mobileButtonStyle = {
  marginTop: "100px",
  border: "none",
  borderRadius: "50%",
  height: 45,
  width: 45,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const buttonStyle = {
  alignSelf: "center",
  border: "none",
  borderRadius: "50%",
  cursor: "pointer",
  height: 60,
  width: 60,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
