import { useState } from "react";
// components
import Carousel from "react-simply-carousel";
import Producer from "./Producer";
// hooks
import { useHandleWindowResize } from "#hooks/useHandleWindowResize";
// constants
import { producersList } from "./constants";

/**
 * Carousel component to display the our producers data
 * @returns Our producers carousel
 */
const OurProducersCarousel = () => {
  /**
   * Hooks
   */
  const { isMobile } = useHandleWindowResize();
  /**
   * States
   */
  const [activeSlide, setActiveSlide] = useState(0);
  /**
   * return
   */
  return (
    <div className="w-full md:mt-8">
      <Carousel
        activeSlideIndex={activeSlide}
        onRequestChange={setActiveSlide}
        forwardBtnProps={{
          children: undefined,
        }}
        backwardBtnProps={{
          children: undefined,
        }}
        visibleSlideProps={{
          style: {
            maxWidth: "1024px",
          },
        }}
        dotsNav={{
          show: isMobile,
          containerProps: {
            style: {
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "2rem",
            },
          },
          itemBtnProps: {
            style: {
              height: 20,
              width: 20,
              borderRadius: "50%",
              border: 0,
              backgroundColor: "#494949",
              margin: "1rem",
            },
          },
          activeItemBtnProps: {
            style: {
              height: 20,
              width: 20,
              borderRadius: "50%",
              border: 0,
              backgroundColor: "#232321",
              margin: "1rem",
            },
          },
        }}
        itemsToShow={isMobile ? 1 : 3}
        speed={400}
        disableSwipeByMouse
        disableSwipeByTouch
      >
        {producersList.map((p, i) => (
          <Producer {...p} key={`producer-${p.name}`} />
        ))}
      </Carousel>
    </div>
  );
};

export default OurProducersCarousel;
