import { useState } from "react";
import Carousel from "react-simply-carousel";
import BeanCharacteristic from "./BeanCharacteristic";
import { BeanCharacteristicsList } from "./constants";

/**
 * Carousel component to display the coffee bean characteristic data
 * @returns Coffee bean characteristic carousel
 */
const CharacteristicsCarousel = () => {
  /**
   * States
   */
  const [activeSlide, setActiveSlide] = useState(0);
  /**
   * return
   */
  return (
    <div>
      <Carousel
        preventScrollOnSwipe
        swipeTreshold={60}
        activeSlideIndex={activeSlide}
        onRequestChange={setActiveSlide}
        forwardBtnProps={{
          children: undefined,
        }}
        backwardBtnProps={{
          children: undefined,
        }}
        dotsNav={{
          show: true,
          containerProps: {
            style: {
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "2rem",
            },
          },
          itemBtnProps: {
            style: {
              height: 20,
              width: 20,
              borderRadius: "50%",
              border: 0,
              backgroundColor: "#634341",
              margin: "1rem",
            },
          },
          activeItemBtnProps: {
            style: {
              height: 20,
              width: 20,
              borderRadius: "50%",
              border: 0,
              backgroundColor: "#A26001",
              margin: "1rem",
            },
          },
        }}
        itemsToShow={1}
        speed={400}
        autoplay
        autoplayDelay={5000}
        disableSwipeByMouse
        disableSwipeByTouch
      >
        {BeanCharacteristicsList.map(bc => (
          <BeanCharacteristic
            characteristic={bc.characteristic}
            description={bc.description}
            Icon={bc.Icon}
            key={`mobile-bean-characteristic-${Math.random()}`}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default CharacteristicsCarousel;
