import { useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
// components
import ProducerCoffeeBatch from "#components/ui/ProducerCoffeeBatch";
// assets
import { ReactComponent as Arrow } from "#assets/svgs/arrow.svg";
// hooks
import { useHandleWindowResize } from "#hooks/useHandleWindowResize";
// constants
import { buttonStyle, mobileButtonStyle } from "./constants";
// types
import { ProducerCarouselProps } from "./types";

/**
 * Carousel component to display the producer's data
 * @returns Producers carousel
 */
const ProducersCarousel = ({ data }: ProducerCarouselProps) => {
  /**
   * Hooks
   */
  const { isMobile } = useHandleWindowResize();
  /**
   * States
   */
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isFirstSlideVisible, setIsFirstSlideVisible] = useState(true);
  const [isLastSlideVisible, setIsLastSlideVisible] = useState(false);
  /**
   * return
   */
  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={(newActiveSlideIndex, newVisibleSlidesState) => {
          setActiveSlideIndex(newActiveSlideIndex);
          setIsFirstSlideVisible(newVisibleSlidesState.isFirstSlideVisible);
          setIsLastSlideVisible(newVisibleSlidesState.isLastSlideVisible);
        }}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          style: isMobile ? mobileButtonStyle : buttonStyle,
          children: isLastSlideVisible ? undefined : (
            <Arrow className="rotate-180" />
          ),
        }}
        backwardBtnProps={{
          style: isMobile ? mobileButtonStyle : buttonStyle,
          children: isFirstSlideVisible ? undefined : <Arrow />,
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="ease-out"
        containerProps={{
          style: {
            flexFlow: "row",
            position: "relative",
          },
        }}
        infinite={false}
        disableSwipeByMouse
        disableSwipeByTouch
      >
        {data?.listProducerInfo?.length ? (
          data?.listProducerInfo.map(producer => {
            return producer.coffeeBatchList.map(coffeeBatch => {
              return (
                <ProducerCoffeeBatch
                  producer={producer.producerInfo}
                  coffeeBatch={coffeeBatch}
                  key={Math.random()}
                />
              );
            });
          })
        ) : (
          <ProducerCoffeeBatch isEmpty />
        )}
      </ReactSimplyCarousel>
    </div>
  );
};

export default ProducersCarousel;
