import { EffectCallback, useEffect } from "react";

/**
 * Just modified version of useEffect that's executed only 
 * one time, at the mounting time.
 * @param effect
 * Usage:
    useEffectOnce(() => {
      console.log('Triggered only once, on mount', { data })
    })
 */
export function useEffectOnce(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}
