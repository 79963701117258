import { FormattedMessage } from "react-intl";
import OurProducersCarousel from "../OurProducersCarousel";

/**
 * OurProducer component to display the our producers data
 * @returns Our Producers section
 */
const OurProducers = () => {
  return (
    <div
      className="
        component-origin-and-certifications-section
        min-h-[595px]
        md:min-h-[620px]
        h-auto
        bg-[url('/src/assets/svgs/mobile-our-producers-bg.svg')]
        md:bg-[url('/src/assets/svgs/our-producers-bg.svg')]
        bg-no-repeat 
        bg-cover
        flex flex-col items-center
        "
      id="our-producers"
    >
      <div
        className="
        max-w-[1300px] 
        flex items-center justify-center flex-col
        w-full 
        py-12 px-6
        text-center
        "
      >
        {/* Mobile */}
        <h4 className="title-4 text-orange-primary md:hidden">
          <FormattedMessage id="our.producers.header" />
        </h4>
        {/* Desktop */}
        <h2 className="hidden md:block title-2 text-orange-primary">
          <FormattedMessage id="our.producers.header" />
        </h2>
        {/* Our producers carousel */}
        <OurProducersCarousel />
      </div>
    </div>
  );
};

export default OurProducers;
