import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

// Container
import YourCoffeeCup from "#containers/YourCoffeeCup";

// Components
import MobileMenu from "#components/templates/MobileMenu";
import Banner from "#components/templates/Banner";
import Footer from "#components/layouts/Footer";
import HeroSection from "#components/templates/HeroSection";
import OriginAndCertifications from "#components/templates/OriginAndCertifications";
import OurProducers from "#components/templates/OurProducers";
import SocialResponsability from "#components/templates/SocialResponsability";
import Spinner from "#components/ui/Spinner";

// Assets
import { ReactComponent as ScrollTop } from "#assets/svgs/arrow-scroll-top.svg";

// Actions
import { getLastFacilityTransactions } from "#actions/facilityTransaction";

// Hooks
import { useEffectOnce } from "#hooks/useEffectOnce";

// Types
import { FacilityTransactionData } from "#containers/OurCoffee/types";

// Others
import { isEmpty } from "lodash";

/**
 * Main App component
 * @returns The Landing page interface
 */
function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<FacilityTransactionData>();
  const [, setIsError] = useState<boolean>(false);

  const { locationId } = useParams();

  /* Scroll top */
  const handleScrollTop = useCallback(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const facilityTransactions = useCallback(async () => {
    try {
      if (locationId) {
        const data: FacilityTransactionData =
          (await getLastFacilityTransactions(
            locationId,
          )) as FacilityTransactionData;

        setData(data);
      }
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [locationId]);

  /* getData from backend */
  useEffectOnce(() => {
    if (isEmpty(data?.listProducerInfo)) {
      facilityTransactions();
    }
  });

  return (
    <div
      className={`
        landing
        bg-dark-secondary 
        flex
        flex-col
        overflow-hidden
        relative
        ${
          isLoading
            ? "h-screen"
            : "h-auto min-[375px]:min-h-[130dvh] lg:min-h-[152vh]"
        }
        `}
    >
      {!isLoading ? (
        <div className="scroll-container">
          <HeroSection />
          <Banner />
          <MobileMenu />
          <YourCoffeeCup data={data} />
          <OurProducers />
          <OriginAndCertifications />
          <SocialResponsability />
          <Footer />
          {/* Arrow scroll top */}
          <button
            className="fixed bottom-[5%] right-[1%] z-20 cursor-[unset] md:hover:cursor-pointer"
            onClick={handleScrollTop}
          >
            <ScrollTop className="w-[75px] h-[75px]" />
          </button>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default App;
