import { ProducerItemProps } from "./types";
// assets
import fernandoGanboa from "#assets/pngs/fernando-ganboa.png";
import aurelioPerez from "#assets/pngs/aurelio-perez.png";
import romanMorales from "#assets/pngs/roman-morales.png";

/**
 * Our producers list
 */
export const producersList: ProducerItemProps[] = [
  {
    imgSrc: fernandoGanboa,
    name: "Fernando Ganboa Robledo",
    age: "54 años",
    acres: "1.74 hectáreas de café",
    plot: "Parcela “El naranjo”, 475 kilos",
    youtubeId: "9bFkf7b-UUA",
  },
  {
    imgSrc: aurelioPerez,
    name: "Aurelio Pérez Bartolón",
    age: "41 años",
    acres: "4.5 hectáreas de café",
    plot: "Parcela “La esperanza”, 2,040 kilos",
    youtubeId: "LAiQkxRBHk0",
  },
  {
    imgSrc: romanMorales,
    name: "Román Morales Mejía",
    age: "63 años",
    acres: "3.0 hectáreas de café",
    plot: "Parcela “El Matazano”, 2,310 kilos",
    youtubeId: "gqDIRrwlhng",
  },
];
