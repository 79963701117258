import { CoffeeQualityEng } from "../components/ui/CoffeeDetails/types";
/**
 * Simulates a delay:
 * create an async timer according to a time in ms
 * @param ms ime represented in milliseconds
 * @returns a resolved promise
 */
export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

/**
 * Gets width and height of a slide that is passed
 * @returns a object with height and width dimensions
 */
export function getSlideDimensions(slide: HTMLDivElement) {
  const width = slide.clientWidth;
  const height = slide.clientHeight;
  return { width, height };
}

/**
 * Removes hours and minutes to a date in format YYYY-MM-DD
 * @returns formatted date DD / MM / YYYY
 */
export const getDate = (fullDate: string) => {
  // remove hrs and mins
  const dateWithoutHour = fullDate.slice(0, 10);

  // get day
  const day = dateWithoutHour.slice(8, 11);

  // get month
  const month = dateWithoutHour.slice(5, 7);

  // get year
  const year = dateWithoutHour.slice(0, 4);

  // formatted date
  const formattedDate = `${day} / ${month} / ${year}`;

  return formattedDate;
};

/**
 * Function that receives value string coming from the backend
 * and @returns an object with the number of filled StarIcons
 * and its correspoding copy
 */
export const getQualityDetails = (value: string) => {
  let quality = {
    starsNumber: 0,
    copy: "",
  };

  switch (value) {
    case CoffeeQualityEng.Best:
      quality.starsNumber = 3;
      quality.copy = "our.coffee.quality.best";
      break;

    case CoffeeQualityEng.High:
      quality.starsNumber = 2;
      quality.copy = "our.coffee.quality.high";
      break;

    case CoffeeQualityEng.Good:
      quality.starsNumber = 1;
      quality.copy = "our.coffee.quality.good";
      break;

    case CoffeeQualityEng.ToBeTested:
      quality.starsNumber = 0;
      quality.copy = "our.coffee.quality.to.be.tested";
      break;

    default:
      return quality;
  }
  return quality;
};

/**
 *  @returns unique key for react components
 *  @param string key
 *  @param number index
 */
export const getKey = (str: string, idx: number) => {
  return encodeURI(`${str},${idx}`);
};
