import cerezos from "#assets/pngs/cerezos.png";
import { YourCoffeeCupProps } from "./types";
import MainCarousel from "./MainCarousel";
import BeanCharacteristics from "./BeanCharacteristics";
import { useMemo } from "react";

/**
 * Your Coffee Cup container
 * @returns YourCoffeeCup container, background and copies
 * handles backend request to display QR Code information
 */
const YourCoffeeCup = ({ data }: YourCoffeeCupProps) => {
  /**
   * Constants
   */
  const producers = useMemo(
    () => data && data?.listProducerInfo?.length > 0,
    [data],
  );
  /**
   * return
   */
  return (
    /* Background */
    <div
      className={`
        component-your-coffee-cup-section
        bg-[url('/src/assets/svgs/mobile-coffee-cup-bg.svg')] 
        bg-no-repeat 
        bg-cover
        h-auto 
        relative 
        md:bg-[url('/src/assets/svgs/desktop-coffee-cup-bg.svg')]
        text-center
        ${producers ? "min-h-[1754px] md:min-h-[1154px]" : ""}
        z-[1]
      `}
      id="your-coffee-cup"
    >
      <div className="h-full w-full absolute top-0 left-0 bottom-0 right-0 bg-black/[0.77] z-[-1]" />

      {/* Left image visible since Mobile */}
      <img
        src={cerezos}
        alt="cerezos"
        className="w-[242px] absolute top-[-50px] md:top-[80px] left-0 lg:w-[290px] z-[1]"
      />

      {/* Main "Your Coffee Cup" section */}
      <MainCarousel data={data} />

      {/* Coffee bean characteristics section */}
      <BeanCharacteristics />
    </div>
  );
};

export default YourCoffeeCup;
