import { useEffect, useMemo, useState } from "react";

/**
 * Calculates window size 
 * 
 * Usage:
     const { isMobile, windowSize } = useHandleWindowResize();
 */
export function useHandleWindowResize() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  /* Window width */
  const isMobile = useMemo(() => {
    return windowSize[0] < 768;
  }, [windowSize]);

  // Handle window size in order to render 1  or 3 elements of the slider
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return { isMobile, windowSize };
}
