import { FormattedMessage } from "react-intl";
import coffeeBag from "#assets/pngs/coffee-bag.png";
import { ProducerCoffeeBatchProps } from "./types";
import CoffeeBatchItem from "./CoffeeBatchItem";
import { getDate } from "#utils/utils";

/**
 * ProducerCoffeeBatch component
 * @returns Producer coffee batch information
 */
const ProducerCoffeeBatch = ({
  producer,
  coffeeBatch,
  isEmpty = false,
}: ProducerCoffeeBatchProps) => {
  return (
    <div
      className="
        flex flex-col md:flex-row justify-center md:justify-evenly
        w-[260px] md:w-[600px] lg:w-[860px] xl:w-[1200px] 2xl:w-[1200px] 
      "
    >
      <div className="bag-section lg:max-w-[50%] flex justify-center items-center">
        <img
          src={coffeeBag}
          alt="Coffee bag"
          className="w-[215px] xl:w-[263px] 2xl:h-[313px]"
        />
      </div>
      {producer && coffeeBatch && !isEmpty ? (
        <div className="coffee-batch-data-section flex flex-col justify-start max-w-[300px] lg:max-w-[582px]">
          {/* Subtitle */}
          <span className="text-orange-primary subtitle-1 hidden md:block text-left">
            <FormattedMessage id="our.coffee.producers.title" />
          </span>
          <div className="flex flex-wrap gap-5 md:gap-10 mt-12">
            <CoffeeBatchItem
              label={"our.coffee.producer.header"}
              value={producer.name}
            />
            <CoffeeBatchItem
              label={"our.coffee.variety.header"}
              value={coffeeBatch.coffeeBatch.types
                .map((type: string, index: number) => {
                  // Add comma if there are more than 1 coffee types,
                  // no comma added to the last element of the list
                  const totalTypes = coffeeBatch.coffeeBatch.types.length;
                  return totalTypes - 1 === index ? `${type}` : `${type}, `;
                })
                .join("")}
            />
            <CoffeeBatchItem
              label={"our.coffee.community.header"}
              value={producer.community}
            />
            <CoffeeBatchItem
              label={"our.coffee.date.of.grinding.and.roasting.header"}
              value={
                coffeeBatch?.roastDatetime
                  ? getDate(coffeeBatch.roastDatetime)
                  : "--/--/----"
              }
            />
            <CoffeeBatchItem
              label={"our.coffee.harvest.date.header"}
              value={getDate(coffeeBatch.harvestDatetime)}
              hideDivider
            />
          </div>
        </div>
      ) : (
        <div
          className="
          empty-coffee-batch-data-section 
          flex flex-col justify-start 
          max-w-[300px] lg:max-w-[565px]
          mt-12 md:mt-16
          text-cream-primary
          "
        >
          <span
            className={
              "font-thin md:mt-6 max-w-[485px] xl:max-w-[565px] text-xl md:text-xl lg:text-2xl xl:text-3xl"
            }
          >
            <FormattedMessage id={"our.coffee.restaurant.not.found"} />
          </span>
        </div>
      )}
    </div>
  );
};

export default ProducerCoffeeBatch;
