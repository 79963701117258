import axios, {
  AxiosRequestConfig,
  AxiosResponseHeaders,
  AxiosResponse,
  Method,
} from "axios";
import merge from "lodash.merge";
import isEmpty from "lodash.isempty";

type TypesRequestData =
  | "headers"
  | "request"
  | "config"
  | "status"
  | "statusText";
type RequestData = Array<TypesRequestData>;

interface ResponseParam {
  data: any;
  headers?: AxiosResponseHeaders;
  request?: any;
  config?: AxiosRequestConfig;
  status?: number;
  statusText?: string;
}

interface Queue {
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
  request: any;
}

/** HTTP client to consume external services */
class HTTPClient {
  /** Save the instance and persist it (singleton) */
  private static client: HTTPClient;
  /** Host or web address of the service */
  private readonly host: string;
  /** Axios configuration to be taken when instantiating HTTPClient  */
  private defaults: AxiosRequestConfig;
  /** Token refresh */
  refreshToken: string;
  /** Arrangement with the status of the petitions that you want to consider for a renegotiation */
  renegotiation: Array<number>;
  /** Callback that handles the renegotiation */
  renegotiate: (value: any) => any;
  /** Arrangement of queued requests */
  queue: Array<Queue>;
  /** Flag that indicates if a request is going to be queued */
  putInQueue: boolean;

  /** Returns the instance if it already exists, otherwise creates it */
  static getClient(host?: string, defaults = {}): HTTPClient {
    if (!HTTPClient.client) {
      HTTPClient.client = new HTTPClient(host, defaults);
    }
    return HTTPClient.client;
  }

  /**
   * @param host If not defined the values point to a relative URL
   * @param defaults "axios" configurator object with default values
   */
  constructor(host?: string, defaults?: AxiosRequestConfig) {
    this.host = host || "";
    this.defaults = defaults || {};
    this.refreshToken = "";
    this.renegotiation = [];
    this.renegotiate = () => null;
    this.queue = [];
    this.putInQueue = false;
  }

  /**
   * Add new default values to instance configuration
   * @param values to mix with client default values
   */
  addDefaults(values: AxiosRequestConfig): void {
    this.defaults = merge(this.defaults || {}, values);
  }

  /**
   * Adds an event listener to renegotiate the authorization token
   * @param codes Array of HTTP Codes to trigger renegotiation.
   * @param fn Function that will perform the renegotiation.
   * */
  renegotiateOn(codes = [], fn = () => null): void {
    this.renegotiation = codes || [];
    this.renegotiate = fn;
  }

  /**
   * Function that resolves queue values
   */
  resolveQueue(): void {
    if (this.queue.length) {
      this.queue.forEach(q => {
        const { method, uri, body, configs, requestData } = q.request;
        this.processAxios(method, uri, body, configs, false, requestData)
          .then(response => {
            q.resolve(response);
          })
          .catch(error => {
            q.reject(error);
          });
      });
    }
    this.putInQueue = false;
    this.queue = [];
  }

  /**
   * Add "Authorization" header to client defaults
   * @param accessToken obtained from Oauth API
   * @param refreshToken obtained from Oauth API refresh
   * @param type of token 'Bearer' or 'Basic' or others
   */
  setAuthorization(
    accessToken: string,
    refreshToken: string,
    type = "Bearer",
  ): void {
    this.addDefaults({ headers: { Authorization: `${type} ${accessToken}` } });
    this.refreshToken = refreshToken;
  }

  /**
   * Execute a GET request
   * @param uri URI of the endpoint to be invoked
   * @param configs axios configurations
   * @param requestData Determines which response attributes are to be returned
   * @returns Promise with the result of the request
   */
  get(uri: string, configs: AxiosRequestConfig, requestData = []): any {
    return this.processAxios("GET", uri, null, configs, true, requestData);
  }

  /**
   * Execute a POST request
   * @param uri URI of the endpoint to be invoked
   * @param body payload to send in axios options
   * @param configs axios configurations
   * @param retry flag so that it does not enter to enqueue ONLY
   * @param requestData Determines which response attributes are to be returned
   * when it is client refresh
   * @returns Promise with the result of the request
   */
  post(
    uri: string,
    body: any,
    configs: AxiosRequestConfig,
    retry: boolean,
    requestData = [],
  ): any {
    return this.processAxios("POST", uri, body, configs, retry, requestData);
  }

  /**
   * Execute a PUT request
   * @param uri URI of the endpoint to be invoked
   * @param body Request body
   * @param configs axios configurations
   * @param retry flag so that it does not enter to enqueue ONLY
   * @param requestData Determines which response attributes are to be returned
   * when it is client refresh
   * @return Promise with the result of the request
   */
  put(
    uri: string,
    body: any,
    configs: AxiosRequestConfig,
    retry: boolean,
    requestData = [],
  ): any {
    return this.processAxios("PUT", uri, body, configs, retry, requestData);
  }

  /**
   * Execute a DELETE request
   * @param uri URI of the endpoint to be invoked
   * @param body Request body
   * @param configs Settings for the "fetch" function
   * @param requestData Determines which response attributes are to be returned
   * @return Promise with the result of the request
   */
  delete(
    uri: string,
    body: any,
    configs: AxiosRequestConfig,
    retry: boolean,
    requestData = [],
  ): any {
    return this.processAxios("DELETE", uri, body, configs, retry, requestData);
  }

  /**
   * Depending on whether `requestData` is not empty, a response is returned in the form
   * of object, where `data` is required and inside `requestData` come
   * the values of the response that you want to append to the response.
   */
  createResponse(
    responseParam: AxiosResponse,
    requestDataParam: RequestData,
  ): ResponseParam {
    if (!isEmpty(requestDataParam)) {
      const res: ResponseParam = { data: responseParam.data };

      if (requestDataParam.includes("headers")) {
        res.headers = responseParam.headers as AxiosResponseHeaders;
      }
      if (requestDataParam.includes("request")) {
        res.request = responseParam.request;
      }
      if (requestDataParam.includes("config")) {
        res.config = responseParam.config;
      }
      if (requestDataParam.includes("status")) {
        res.status = responseParam.status;
      }
      if (requestDataParam.includes("statusText")) {
        res.statusText = responseParam.statusText;
      }

      return res;
    }
    return responseParam.data;
  }

  /**
   * Execute an HTTP request to a resource
   * @param method Method HTTP
   * @param uri URI of the endpoint to be invoked
   * @param body Request body
   * @param configs axios configurations
   * @returns Promise with the result of the request
   */
  private processAxios(
    method: Method,
    uri: string,
    body = null,
    configs?: AxiosRequestConfig,
    retry = true,
    requestData = [],
  ): Promise<any> {
    const url = `${this.host}${uri}`;
    const configHeaders = configs ? configs.headers : {};
    const headers = { ...this.defaults.headers, ...configHeaders };
    const options = {
      ...this.defaults,
      ...configs,
      method,
      url,
      headers,
    };
    if (body) {
      options.data = body;
    }
    return new Promise((resolve, reject) => {
      // If it is in renegotiation process, the request is queued and sent until the process is finished
      if (retry && this.putInQueue) {
        this.queue.push({
          resolve,
          reject,
          request: options,
        });
        return;
      }

      axios(options)
        .then(async response => {
          const newResponse = this.createResponse(response, requestData);
          resolve(newResponse);
        })
        .catch(async error => {
          // Renegotiation process
          if (
            this.renegotiation.includes(error?.response?.status) &&
            error?.response?.data?.fault?.description === "Unauthorized" &&
            this.renegotiate &&
            retry
          ) {
            this.putInQueue = true;
            this.queue.push({
              resolve,
              reject,
              request: { method, uri, body, configs, requestData },
            });
            try {
              await this.renegotiate(this);
            } catch (e) {
              console.error(e);
            }
            this.resolveQueue();
            return;
          }
          reject(error.response?.data || error);
        });
    });
  }
}

export default HTTPClient;
