import ErrorScreen from "#screens/ErrorScreen";
import React from "react";

const NotFound = () => {
  return (
    <div className="w-full h-screen">
      <ErrorScreen isNotFound />
    </div>
  );
};

export default NotFound;
