import { FormattedMessage } from "react-intl";
import { ReactComponent as ButtonBg } from "#assets/svgs/button-bg.svg";
import { MenuButtonProps } from "./types";

/**
 * Menu button component
 * @renders anchor
 */
const MenuButton = (props: MenuButtonProps) => {
  const { title, href, Icon } = props;
  return (
    <a
      href={href}
      className="flex justify-center items-center my-3 text-orange-primary focus:outline-none focus-visible:none button-title button-anchor"
    >
      <ButtonBg className="button-bg" />
      <Icon className="mr-3" />
      <FormattedMessage id={title} />
    </a>
  );
};

export default MenuButton;
