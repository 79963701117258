import { ReactComponent as CoffeeBeansMobile } from "#assets/svgs/right-coffee-beans.svg";
import { ReactComponent as CoffeeBeansDesktop } from "#assets/svgs/left-coffee-beans.svg";
import { ReactComponent as Cables } from "#assets/svgs/error-cables.svg";
import { FormattedMessage } from "react-intl";
import { ErrorScreenProps } from "./types";

const ErrorScreen = ({ isNotFound }: ErrorScreenProps) => {
  return (
    <div
      className={`w-full h-full bg-dark-secondary flex flex-col  px-[30px] overflow-hidden ${
        isNotFound ? "" : "rounded-[15px] pt-[120px] pb-[120px]"
      } `}
    >
      <div className="flex flex-col w-full h-full relative justify-center items-center text-center ">
        {/* Coffee beans */}
        <div className="flex justify-center items-center mb-[40px] md:mb-[50px]">
          <CoffeeBeansMobile className="lg:w-[75px] lg:h-[75px]" />
          <CoffeeBeansDesktop className="lg:w-[85px] lg:h-[85px]" />
        </div>

        {/* Header error info */}
        <span className="subtitle-1 text-cream-primary mb-[80px] md:mb-[90px] lg:text-3xl ">
          <FormattedMessage id="general.error.screen.message" />
        </span>

        {/* svg cables */}
        <Cables className="lg:w-[590px] lg:h-[55px]" />
      </div>
    </div>
  );
};

export default ErrorScreen;
