import { BeanCharacteristicProps } from "./types";
import { ReactComponent as BeanCharacterOne } from "#assets/svgs/bean-characteristic-1.svg";
import { ReactComponent as BeanCharacterTwo } from "#assets/svgs/bean-characteristic-2.svg";

/**
 * Bean characteristics list
 */
export const BeanCharacteristicsList: BeanCharacteristicProps[] = [
  {
    characteristic: "our.coffee.characteristics.smell.title",
    description: "our.coffee.characteristics.smell.description",
    Icon: BeanCharacterOne,
  },
  {
    characteristic: "our.coffee.characteristics.taste.title",
    description: "our.coffee.characteristics.taste.description",
    Icon: BeanCharacterTwo,
  },
  {
    characteristic: "our.coffee.characteristics.acidity.title",
    description: "our.coffee.characteristics.acidity.description",
    Icon: BeanCharacterOne,
  },
  {
    characteristic: "our.coffee.characteristics.body.title",
    description: "our.coffee.characteristics.body.description",
    Icon: BeanCharacterTwo,
  },
  {
    characteristic: "our.coffee.characteristics.sweetness.title",
    description: "our.coffee.characteristics.sweetness.description",
    Icon: BeanCharacterOne,
  },
];
