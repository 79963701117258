import { MENU_OPTIONS } from "#constants/MenuOptions";
import MenuButton from "#components/ui/MenuButton";

/**
 * Mobile Menu component
 * @renders Mobile menu options for small screen size
 */
const MobileMenu = () => {
  return (
    <div
      className="
        component-mobile-menu
        md:hidden 
        flex 
        justify-center 
        items-center 
        bg-dark-primary 
        pb-12 px-6"
    >
      <div className="flex flex-col">
        {MENU_OPTIONS.map(option => (
          <MenuButton
            key={option.id}
            title={`general.menu.${option.id}`}
            href={option.to}
            Icon={option.MobileIcon}
          />
        ))}
      </div>
    </div>
  );
};

export default MobileMenu;
