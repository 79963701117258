import { useCallback, useState } from "react";
// components
import YoutubeModal from "./YoutubeModal";
import PlayButton from "./PlayButton";
// types
import { ProducerItemProps } from "./types";

/**
 * Producer component
 * @returns the producer data
 */
const Producer = (props: ProducerItemProps) => {
  /**
   * States
   */
  const [isModalOpen, setIsModalOpen] = useState(false);
  /**
   * Callbacks
   */
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  /**
   * return
   */
  return (
    <div
      className="w-[240px] lg:w-[320px] xl:w-[400px] flex items-center flex-col"
      key={`producer-${props.name}`}
    >
      <img
        className="inline-block h-120 w-120 rounded-full m-8"
        src={props.imgSrc}
        alt={props.name}
      />
      <span className="producer-name mb-4">{props.name}</span>
      <span className="producer-text">{props.age}</span>
      <span className="producer-text">{props.acres}</span>
      <span className="producer-text">{props.plot}</span>
      <div className="my-8">
        <PlayButton onClick={openModal} />
      </div>
      <YoutubeModal
        youtubeId={props.youtubeId}
        open={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export default Producer;
