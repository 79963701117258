import { FormattedMessage } from "react-intl";
import ProducersCarousel from "#components/templates/ProducersCarousel";
import { MainCarouselProps } from "./types";
import { useMemo } from "react";

/**
 * Main carousel of the Landing page
 * @returns the producers carousel
 */
const MainCarousel = ({ data }: MainCarouselProps) => {
  /**
   * Constants
   */
  const producers = useMemo(
    () => data && data?.listProducerInfo?.length > 0,
    [data],
  );
  /**
   * return
   */
  return (
    <div className="producers-section linear-gradient-180deg flex justify-center items-center pb-10 pt-16 px-4 md:px-6 lg:justify-center">
      <div
        className={`
        transform-180 
        max-w-[1300px] 
        ${producers ? "min-h-[1110px] md:min-h-[575px]" : ""} 
        flex items-center flex-col 
        w-full 
        pt-16 md:pt-0
      `}
      >
        {/* Mobile */}
        <h4 className="title-4 text-orange-primary md:hidden">
          <FormattedMessage id="our.coffee.header" />
        </h4>
        {/* Desktop */}
        <h2 className="hidden md:block title-2 text-orange-primary">
          <FormattedMessage id="our.coffee.header" />
        </h2>
        {/* Subtitle */}
        <span className="text-cream-primary subtitle-1-1 max-w-[338px] md:max-w-[480px] lg:max-w-[665px] my-10">
          <FormattedMessage id="our.coffee.subtitle" />
        </span>

        {/* Producers carousel */}
        <div className="w-full my-0 md:mt-10 md:mb-0">
          {/* Subtitle */}
          {producers && (
            <span className="text-orange-primary subtitle-1 block md:hidden text-center mb-10">
              <FormattedMessage id="our.coffee.producers.title" />
            </span>
          )}
          <ProducersCarousel data={data} />
        </div>
      </div>
    </div>
  );
};

export default MainCarousel;
