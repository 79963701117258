import { ReactComponent as Logo } from "#assets/svgs/mobile-toks-logo.svg";
import { ReactComponent as CoffeeBeansMobile } from "#assets/svgs/right-coffee-beans.svg";
import { ReactComponent as CoffeeBeansDesktop } from "#assets/svgs/left-coffee-beans.svg";

/**
 * @renders logo
 */
export type HeaderProps = {};

const Header = (_: HeaderProps) => {
  return (
    <header>
      <div
        className={
          "component-header flex items justify-between items-center mb-8 md:justify-center lg:mb-12 xl:mb-[4rem] relative z-0"
        }
      >
        <div className="relative">
          <CoffeeBeansDesktop className="absolute right-[80px] bottom-[-4px] hidden md:block md:right-[100px] md:-bottom-3 lg:right-[130px] xl:right-[175px]" />
          <Logo className="md:h-[80px] md:w-[90px] lg:w-[120px] lg:h-[120px] xl:w-[160px] xl:h-[160px]" />
          <CoffeeBeansMobile className="absolute left-[100px] bottom-[-4px] md:left-[95px] md:-bottom-3 lg:left-[125px] xl:left-[170px]" />
        </div>
      </div>
    </header>
  );
};

export default Header;
