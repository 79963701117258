import { MenuOptions } from "#components/layouts/Header/types";
import { ReactComponent as OriginAndCertifications } from "#assets/svgs/origin-and-certifications.svg";
import { ReactComponent as MobileOriginAndCertifications } from "#assets/svgs/mobile-origin-and-certifications.svg";
import { ReactComponent as YourCoffeeCup } from "#assets/svgs/your-coffee-cup.svg";
import { ReactComponent as MobileYourCoffeeCup } from "#assets/svgs/mobile-your-coffee-cup.svg";
import { ReactComponent as OurProducers } from "#assets/svgs/our-producers.svg";
import { ReactComponent as MobileOurProducers } from "#assets/svgs/mobile-our-producers.svg";
import { ReactComponent as SocialResponsability } from "#assets/svgs/social-responsability.svg";
import { ReactComponent as MobileSocialResponsability } from "#assets/svgs/mobile-social-responsability.svg";

/**
 * MenuOptions
 * @returns Array of menu options
 * mobile -> used in the sidebar
 * desktop -> used in the banner section just after the hero section
 */
export const MENU_OPTIONS: Array<MenuOptions> = [
  {
    id: "your.coffee.cup",
    to: "#your-coffee-cup",
    Icon: YourCoffeeCup,
    MobileIcon: MobileYourCoffeeCup,
  },
  {
    id: "our.producers",
    to: "#our-producers",
    Icon: OurProducers,
    MobileIcon: MobileOurProducers,
  },
  {
    id: "origin.and.certifications",
    to: "#origin-and-certifications",
    Icon: OriginAndCertifications,
    MobileIcon: MobileOriginAndCertifications,
  },
  {
    id: "social.responsability",
    to: "#social-responsability",
    Icon: SocialResponsability,
    MobileIcon: MobileSocialResponsability,
  },
];
