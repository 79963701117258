import { BrowserRouter, Routes, Route } from "react-router-dom";
//api
import HTTPClient from "#api/HTTPClient";
//constants
import { REQUEST_TIMEOUT } from "#constants/API";
//routes
import ROUTES from "#routes/index";

/**
 * BrowserRouter's basename
 */
const basename = process.env.REACT_APP_PUBLIC_URL ?? "/";

/**
 * Initialize HttpClient with the Middleware host url
 */
HTTPClient.getClient(`${process.env.REACT_APP_MIDDLEWARE_HOST}`, {
  timeout: REQUEST_TIMEOUT * 1000,
});

/**
 * App Main Component
 * @returns <App />
 */
const App = () => {
  return (
    <div className="app">
      <BrowserRouter basename={basename}>
        <Routes>
          {ROUTES.map((route, i) => (
            <Route
              path={route.path}
              Component={route.component}
              key={`route-${i + 1}`}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
