import { FormattedMessage } from "react-intl";

/**
 * Hero content component
 * @renders Title and subtitle used in the Hero section
 */
const HeroContent = () => {
  return (
    <div className="component-hero-content flex flex-col items-center">
      {/* Mobile */}
      <h3 className="text-cream-primary max-w-xs mb-5 md:hidden mb-15 md:max-w-none ">
        <FormattedMessage
          id="general.hero.title"
          data-testid="cononoce-el-origen"
        />
      </h3>
      <span className="text-orange-primary subtitle-2 mb-6 md:hidden">
        <FormattedMessage
          id="general.hero.subtitle"
          data-testid="desde-Chiapas"
        />
      </span>

      {/* Desktop */}
      <h1 className="text-cream-primary hidden md:block md:w-[445px] lg:w-[845px] mb-15 md:mb-10 lg:mb-8 xl:mb-[2rem]">
        <FormattedMessage id="general.hero.title" />
      </h1>
      <span className="text-orange-primary hidden subtitle-1 md:block">
        <FormattedMessage id="general.hero.subtitle" />
      </span>
    </div>
  );
};

export default HeroContent;
