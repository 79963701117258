import { FormattedMessage } from "react-intl";
import loading from "#assets/gifs/loading.gif";
import { SpinnerProps } from "./types";
import "./style.css";

const Spinner = ({ classNames }: SpinnerProps) => {
  return (
    <div
      className="
      w-full h-full 
      flex flex-col justify-center items-center 
      px-4 
      bg-[#16140E] 
      text-center text-cream-primary
      "
    >
      <img
        src={loading}
        alt="loading toks"
        className={`${classNames}`}
        width={250}
      />

      {/* Mobile */}
      <span className="md:hidden loader-text-mobile max-w-[270px]">
        <FormattedMessage id="general.loading.screen.message" />
      </span>

      {/* Desktop */}
      <span className="hidden md:block loader-text">
        <FormattedMessage id="general.loading.screen.message" />
      </span>
    </div>
  );
};

export default Spinner;
