import InfoComponent from "#components/ui/InfoComponent";
import { FormattedMessage } from "react-intl";

// Icons and image
import { ReactComponent as EcoIcon } from "#assets/svgs/eco-icon.svg";
import { ReactComponent as CareIcon } from "#assets/svgs/care-icon.svg";
import { ReactComponent as WorldIcon } from "#assets/svgs/world-icon.svg";
import harvesting from "#assets/pngs/harvesting.png";

const SocialResponsability = () => {
  return (
    <div
      className="
        component-social-responsability
        bg-dark-primary
        text-cream-primary
        pt-10
        md:pt-5
        px-6 
        pb-12
        flex 
        flex-col 
        justify-center 
        items-center
        md:px-9
        lg:px-16
        lg:pt-[32px]
        "
      id="social-responsability"
    >
      <div className="max-w-[330px] md:max-w-[1300px] w-full md:justify-between">
        {/* Mobile */}
        <h4 className="title-4 text-orange-primary text-center mb-10 md:hidden">
          <FormattedMessage
            id="social.responsability.section.header"
            values={{ break: <br /> }}
          />
        </h4>

        {/* Desktop */}
        <h2 className="hidden md:block mb-16 md:text-center title-2 text-orange-primary">
          <FormattedMessage
            id="social.responsability.section.header"
            values={{ break: <br /> }}
          />
        </h2>
        <div className="flex flex-col md:flex-row gap-x-[50px] md:items-center md:justify-between">
          <div className="max-w-[562px]">
            <InfoComponent
              subtitle="social.responsability.section.subtitle"
              paragraph="social.responsability.section.paragraph"
              isSocialResp
            />
            <div className="hidden md:flex gap-[25px] mt-9 mb-16">
              <EcoIcon />
              <CareIcon />
              <WorldIcon />
            </div>
          </div>
          <div className="mt-10 md:mt-0 flex items-center justify-between">
            <div>
              <img
                src={harvesting}
                alt="coffee harvesting"
                className="
                max-w-[230px]
                md:max-w-[420px]
                lg:mt-[-20%]
                xl:w-[448px]"
              />
            </div>
            <div className="flex flex-col md:hidden gap-[25px]">
              <EcoIcon />
              <CareIcon />
              <WorldIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialResponsability;
