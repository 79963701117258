import { FormattedMessage } from "react-intl";
import { BannerItemProps } from "./types";

/**
 * Individual Banner item
 * @renders svg Icon and its corresponding text
 */
const BannerItem = (props: BannerItemProps) => {
  const { message, Icon } = props;

  return (
    <div className="component-banner-item text-center flex justify-center flex-col items-center gap-y-2 lg:gap-y-3 xl:justify-center">
      <Icon className="w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] xl:w-[70px] xl:h-[70px] " />
      <p className="max-[1024px]:text-[16px] max-[1024px]:font-normal subtitle-2 text-cream-primary">
        <FormattedMessage id={message} values={{ break: <br /> }} />
      </p>
    </div>
  );
};

export default BannerItem;
