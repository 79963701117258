import cupOfCoffee from "#assets/pngs/cup-of-coffee.png";
import { MENU_OPTIONS } from "#constants/MenuOptions";
import BannerItem from "#components/ui/BannerItem";

/**
 * Banner component
 * @renders Cup of coffee svg and menu options for md screen size
 */
const Banner = () => {
  return (
    <div
      className="
        component-banner-item
        flex 
        justify-center 
        items-center
        gap-x-[6.5%] 
        bg-dark-primary 
        pb-4 px-6  
        lg:justify-center 
        lg:px-[6%]
      "
    >
      <div className="max-w-[1300px] flex justify-center items-center w-full pb-6 md:pb-12 md:justify-evenly">
        <div className="hidden md:flex flex-col gap-y-[60px] lg:gap-y-[90px]">
          {MENU_OPTIONS.slice(0, 2).map(option => (
            <a
              href={option.to}
              key={option.id}
              className="transition duration-200 ease-in-out hover:ease-in hover:scale-125"
            >
              <BannerItem
                message={`general.menu.${option.id}`}
                Icon={option.Icon}
              />
            </a>
          ))}
        </div>

        <img
          src={cupOfCoffee}
          className="lg:w-[330px] lg:h-auto xl:w-[430px]"
          alt="cup-of-coffee"
          loading="lazy"
        />

        <div className="hidden md:flex flex-col gap-y-[60px] lg:gap-y-[90px]">
          {MENU_OPTIONS.slice(2, 4).map(option => (
            <a
              href={option.to}
              key={option.id}
              className="transition duration-200 ease-in-out hover:ease-in hover:scale-125"
            >
              <BannerItem
                message={`general.menu.${option.id}`}
                Icon={option.Icon}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Banner;
