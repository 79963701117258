import { FormattedMessage } from "react-intl";
import { CoffeeBatchItemProps } from "./types";

const CoffeeBatchItem = ({
  label,
  value,
  hideDivider = false,
}: CoffeeBatchItemProps) => {
  return (
    <div className="w-[260px] text-cream-primary flex flex-col items-center md:items-start">
      <span className="producer-label mb-[5px]">
        <FormattedMessage id={label} />
      </span>
      <span className="producer-value text-center md:text-left">{value}</span>
      {!hideDivider && (
        <div className="w-[260px] md:w-[156px] bg-orange-primary h-[1px] mt-6"></div>
      )}
    </div>
  );
};

export default CoffeeBatchItem;
