import { FormattedMessage } from "react-intl";
import { InfoComponentProps } from "./types";

// Icons
import { ReactComponent as CertifiedLogo } from "../../../assets/svgs/certified-logo.svg";
import { ReactComponent as RainforestLogo } from "../../../assets/svgs/rainforest-logo.svg";

const InfoComponent = ({
  subtitle,
  paragraph,
  className,
  certifications = false,
  isSocialResp = false,
}: InfoComponentProps) => {
  return (
    <div
      className={`
        component-producers-tacana
        text-cream-primary
        flex 
        flex-col 
        ${!isSocialResp && "gap-[12px]"}
        ${!isSocialResp ? "max-w-[343px]" : "unset"}
        md:min-w-[300px] 
        lg:min-w-[440px] 
        xl:min-w-[556px]
        ${className}`}
    >
      <span
        className={`${
          isSocialResp ? "subtitle-1 mb-4 md:mb-5" : "subtitle-1 my-6 mb:my-8"
        }`}
      >
        <FormattedMessage id={subtitle} />
      </span>
      <p className="paragraph">
        <FormattedMessage id={paragraph} values={{ break: <br /> }} />
      </p>
      {certifications && (
        <div className="flex justify-center md:justify-start mt-5 md:mt-10 gap-7 md:gap-12 ">
          <CertifiedLogo className="w-[96px] h-[96px] md:w-[70px] md:h-[70px] lg:w-[90px] lg:h-[90px]" />
          <RainforestLogo className="w-[96px] h-[96px] md:w-[70px] md:h-[70px] lg:w-[90px] lg:h-[90px]" />
        </div>
      )}
    </div>
  );
};

export default InfoComponent;
